// source: experiment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.Experiment', null, global);
goog.exportSymbol('proto.Experiment.ContextImageCoordinateInfo', null, global);
goog.exportSymbol('proto.Experiment.Location', null, global);
goog.exportSymbol('proto.Experiment.Location.BeamLocation', null, global);
goog.exportSymbol('proto.Experiment.Location.BeamLocation.Coordinate2D', null, global);
goog.exportSymbol('proto.Experiment.Location.DetectorSpectrum', null, global);
goog.exportSymbol('proto.Experiment.Location.MetaDataItem', null, global);
goog.exportSymbol('proto.Experiment.Location.PseudoIntensityData', null, global);
goog.exportSymbol('proto.Experiment.Location.SpectrumCompression', null, global);
goog.exportSymbol('proto.Experiment.MatchedContextImageInfo', null, global);
goog.exportSymbol('proto.Experiment.MetaDataType', null, global);
goog.exportSymbol('proto.Experiment.PseudoIntensityRange', null, global);
goog.exportSymbol('proto.Experiment.ScanSource', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Experiment.repeatedFields_, null);
};
goog.inherits(proto.Experiment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.displayName = 'proto.Experiment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.ScanSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Experiment.ScanSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.ScanSource.displayName = 'proto.Experiment.ScanSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.ContextImageCoordinateInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Experiment.ContextImageCoordinateInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.ContextImageCoordinateInfo.displayName = 'proto.Experiment.ContextImageCoordinateInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.MatchedContextImageInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Experiment.MatchedContextImageInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.MatchedContextImageInfo.displayName = 'proto.Experiment.MatchedContextImageInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Experiment.Location.repeatedFields_, null);
};
goog.inherits(proto.Experiment.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.Location.displayName = 'proto.Experiment.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.Location.MetaDataItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Experiment.Location.MetaDataItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.Location.MetaDataItem.displayName = 'proto.Experiment.Location.MetaDataItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.Location.BeamLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Experiment.Location.BeamLocation.repeatedFields_, null);
};
goog.inherits(proto.Experiment.Location.BeamLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.Location.BeamLocation.displayName = 'proto.Experiment.Location.BeamLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.Location.BeamLocation.Coordinate2D = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Experiment.Location.BeamLocation.Coordinate2D, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.Location.BeamLocation.Coordinate2D.displayName = 'proto.Experiment.Location.BeamLocation.Coordinate2D';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.Location.DetectorSpectrum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Experiment.Location.DetectorSpectrum.repeatedFields_, null);
};
goog.inherits(proto.Experiment.Location.DetectorSpectrum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.Location.DetectorSpectrum.displayName = 'proto.Experiment.Location.DetectorSpectrum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.Location.PseudoIntensityData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Experiment.Location.PseudoIntensityData.repeatedFields_, null);
};
goog.inherits(proto.Experiment.Location.PseudoIntensityData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.Location.PseudoIntensityData.displayName = 'proto.Experiment.Location.PseudoIntensityData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Experiment.PseudoIntensityRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Experiment.PseudoIntensityRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Experiment.PseudoIntensityRange.displayName = 'proto.Experiment.PseudoIntensityRange';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Experiment.repeatedFields_ = [27,24,6,26,3,4,5,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driveId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    target: jspb.Message.getFieldWithDefault(msg, 13, ""),
    site: jspb.Message.getFieldWithDefault(msg, 14, ""),
    title: jspb.Message.getFieldWithDefault(msg, 15, ""),
    sol: jspb.Message.getFieldWithDefault(msg, 25, ""),
    rtt: jspb.Message.getFieldWithDefault(msg, 17, 0),
    sclk: jspb.Message.getFieldWithDefault(msg, 18, 0),
    bulkSumQuantFile: jspb.Message.getFieldWithDefault(msg, 9, ""),
    detectorConfig: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bulkSpectra: jspb.Message.getFieldWithDefault(msg, 19, 0),
    dwellSpectra: jspb.Message.getFieldWithDefault(msg, 20, 0),
    maxSpectra: jspb.Message.getFieldWithDefault(msg, 21, 0),
    normalSpectra: jspb.Message.getFieldWithDefault(msg, 22, 0),
    pseudoIntensities: jspb.Message.getFieldWithDefault(msg, 23, 0),
    mainContextImage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scanSourcesList: jspb.Message.toObjectList(msg.getScanSourcesList(),
    proto.Experiment.ScanSource.toObject, includeInstance),
    alignedContextImagesList: jspb.Message.toObjectList(msg.getAlignedContextImagesList(),
    proto.Experiment.ContextImageCoordinateInfo.toObject, includeInstance),
    unalignedContextImagesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    matchedAlignedContextImagesList: jspb.Message.toObjectList(msg.getMatchedAlignedContextImagesList(),
    proto.Experiment.MatchedContextImageInfo.toObject, includeInstance),
    metaLabelsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    metaTypesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.Experiment.Location.toObject, includeInstance),
    pseudoIntensityRangesList: jspb.Message.toObjectList(msg.getPseudoIntensityRangesList(),
    proto.Experiment.PseudoIntensityRange.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment}
 */
proto.Experiment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment;
  return proto.Experiment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment}
 */
proto.Experiment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDriveId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSite(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSol(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRtt(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSclk(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBulkSumQuantFile(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectorConfig(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBulkSpectra(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDwellSpectra(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxSpectra(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNormalSpectra(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPseudoIntensities(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainContextImage(value);
      break;
    case 27:
      var value = new proto.Experiment.ScanSource;
      reader.readMessage(value,proto.Experiment.ScanSource.deserializeBinaryFromReader);
      msg.addScanSources(value);
      break;
    case 24:
      var value = new proto.Experiment.ContextImageCoordinateInfo;
      reader.readMessage(value,proto.Experiment.ContextImageCoordinateInfo.deserializeBinaryFromReader);
      msg.addAlignedContextImages(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnalignedContextImages(value);
      break;
    case 26:
      var value = new proto.Experiment.MatchedContextImageInfo;
      reader.readMessage(value,proto.Experiment.MatchedContextImageInfo.deserializeBinaryFromReader);
      msg.addMatchedAlignedContextImages(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addMetaLabels(value);
      break;
    case 4:
      var value = /** @type {!Array<!proto.Experiment.MetaDataType>} */ (reader.readPackedEnum());
      msg.setMetaTypesList(value);
      break;
    case 5:
      var value = new proto.Experiment.Location;
      reader.readMessage(value,proto.Experiment.Location.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 8:
      var value = new proto.Experiment.PseudoIntensityRange;
      reader.readMessage(value,proto.Experiment.PseudoIntensityRange.deserializeBinaryFromReader);
      msg.addPseudoIntensityRanges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriveId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSite();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSol();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getRtt();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getSclk();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getBulkSumQuantFile();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDetectorConfig();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBulkSpectra();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getDwellSpectra();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getMaxSpectra();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getNormalSpectra();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getPseudoIntensities();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getMainContextImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScanSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.Experiment.ScanSource.serializeBinaryToWriter
    );
  }
  f = message.getAlignedContextImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.Experiment.ContextImageCoordinateInfo.serializeBinaryToWriter
    );
  }
  f = message.getUnalignedContextImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getMatchedAlignedContextImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.Experiment.MatchedContextImageInfo.serializeBinaryToWriter
    );
  }
  f = message.getMetaLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getMetaTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Experiment.Location.serializeBinaryToWriter
    );
  }
  f = message.getPseudoIntensityRangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.Experiment.PseudoIntensityRange.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.Experiment.MetaDataType = {
  MT_FLOAT: 0,
  MT_INT: 1,
  MT_STRING: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.ScanSource.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.ScanSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.ScanSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.ScanSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    instrument: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driveId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    target: jspb.Message.getFieldWithDefault(msg, 5, ""),
    site: jspb.Message.getFieldWithDefault(msg, 6, ""),
    title: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sol: jspb.Message.getFieldWithDefault(msg, 8, ""),
    rtt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    sclk: jspb.Message.getFieldWithDefault(msg, 10, 0),
    bulkSumQuantFile: jspb.Message.getFieldWithDefault(msg, 11, ""),
    detectorConfig: jspb.Message.getFieldWithDefault(msg, 12, ""),
    idOffset: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.ScanSource}
 */
proto.Experiment.ScanSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.ScanSource;
  return proto.Experiment.ScanSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.ScanSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.ScanSource}
 */
proto.Experiment.ScanSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDriveId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSiteId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSite(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSol(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRtt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSclk(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBulkSumQuantFile(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectorConfig(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIdOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.ScanSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.ScanSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.ScanSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.ScanSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrument();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriveId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSite();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSol();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRtt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSclk();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getBulkSumQuantFile();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDetectorConfig();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIdOffset();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional string instrument = 1;
 * @return {string}
 */
proto.Experiment.ScanSource.prototype.getInstrument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setInstrument = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target_id = 2;
 * @return {string}
 */
proto.Experiment.ScanSource.prototype.getTargetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setTargetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 drive_id = 3;
 * @return {number}
 */
proto.Experiment.ScanSource.prototype.getDriveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setDriveId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 site_id = 4;
 * @return {number}
 */
proto.Experiment.ScanSource.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string target = 5;
 * @return {string}
 */
proto.Experiment.ScanSource.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string site = 6;
 * @return {string}
 */
proto.Experiment.ScanSource.prototype.getSite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setSite = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string title = 7;
 * @return {string}
 */
proto.Experiment.ScanSource.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sol = 8;
 * @return {string}
 */
proto.Experiment.ScanSource.prototype.getSol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setSol = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string rtt = 9;
 * @return {string}
 */
proto.Experiment.ScanSource.prototype.getRtt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setRtt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 sclk = 10;
 * @return {number}
 */
proto.Experiment.ScanSource.prototype.getSclk = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setSclk = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string bulk_sum_quant_file = 11;
 * @return {string}
 */
proto.Experiment.ScanSource.prototype.getBulkSumQuantFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setBulkSumQuantFile = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string detector_config = 12;
 * @return {string}
 */
proto.Experiment.ScanSource.prototype.getDetectorConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setDetectorConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 id_offset = 13;
 * @return {number}
 */
proto.Experiment.ScanSource.prototype.getIdOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.ScanSource} returns this
 */
proto.Experiment.ScanSource.prototype.setIdOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.ContextImageCoordinateInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.ContextImageCoordinateInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.ContextImageCoordinateInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pmc: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trapezoidCorrected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    scanSource: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.ContextImageCoordinateInfo}
 */
proto.Experiment.ContextImageCoordinateInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.ContextImageCoordinateInfo;
  return proto.Experiment.ContextImageCoordinateInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.ContextImageCoordinateInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.ContextImageCoordinateInfo}
 */
proto.Experiment.ContextImageCoordinateInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPmc(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrapezoidCorrected(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScanSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.ContextImageCoordinateInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.ContextImageCoordinateInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.ContextImageCoordinateInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPmc();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTrapezoidCorrected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getScanSource();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string image = 1;
 * @return {string}
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.ContextImageCoordinateInfo} returns this
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 pmc = 2;
 * @return {number}
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.getPmc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.ContextImageCoordinateInfo} returns this
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.setPmc = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool trapezoid_corrected = 3;
 * @return {boolean}
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.getTrapezoidCorrected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Experiment.ContextImageCoordinateInfo} returns this
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.setTrapezoidCorrected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 scan_source = 4;
 * @return {number}
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.getScanSource = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.ContextImageCoordinateInfo} returns this
 */
proto.Experiment.ContextImageCoordinateInfo.prototype.setScanSource = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.MatchedContextImageInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.MatchedContextImageInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.MatchedContextImageInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.MatchedContextImageInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alignedIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    xOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    yOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    xScale: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    yScale: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.MatchedContextImageInfo}
 */
proto.Experiment.MatchedContextImageInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.MatchedContextImageInfo;
  return proto.Experiment.MatchedContextImageInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.MatchedContextImageInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.MatchedContextImageInfo}
 */
proto.Experiment.MatchedContextImageInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAlignedIndex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setXOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setYOffset(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setXScale(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setYScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.MatchedContextImageInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.MatchedContextImageInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.MatchedContextImageInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.MatchedContextImageInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlignedIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getXOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getYOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getXScale();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getYScale();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string image = 1;
 * @return {string}
 */
proto.Experiment.MatchedContextImageInfo.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.MatchedContextImageInfo} returns this
 */
proto.Experiment.MatchedContextImageInfo.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 aligned_index = 2;
 * @return {number}
 */
proto.Experiment.MatchedContextImageInfo.prototype.getAlignedIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.MatchedContextImageInfo} returns this
 */
proto.Experiment.MatchedContextImageInfo.prototype.setAlignedIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float x_offset = 3;
 * @return {number}
 */
proto.Experiment.MatchedContextImageInfo.prototype.getXOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.MatchedContextImageInfo} returns this
 */
proto.Experiment.MatchedContextImageInfo.prototype.setXOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float y_offset = 4;
 * @return {number}
 */
proto.Experiment.MatchedContextImageInfo.prototype.getYOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.MatchedContextImageInfo} returns this
 */
proto.Experiment.MatchedContextImageInfo.prototype.setYOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float x_scale = 5;
 * @return {number}
 */
proto.Experiment.MatchedContextImageInfo.prototype.getXScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.MatchedContextImageInfo} returns this
 */
proto.Experiment.MatchedContextImageInfo.prototype.setXScale = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float y_scale = 6;
 * @return {number}
 */
proto.Experiment.MatchedContextImageInfo.prototype.getYScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.MatchedContextImageInfo} returns this
 */
proto.Experiment.MatchedContextImageInfo.prototype.setYScale = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Experiment.Location.repeatedFields_ = [2,5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanSource: jspb.Message.getFieldWithDefault(msg, 9, 0),
    metaList: jspb.Message.toObjectList(msg.getMetaList(),
    proto.Experiment.Location.MetaDataItem.toObject, includeInstance),
    beam: (f = msg.getBeam()) && proto.Experiment.Location.BeamLocation.toObject(includeInstance, f),
    spectrumCompression: jspb.Message.getFieldWithDefault(msg, 4, 0),
    detectorsList: jspb.Message.toObjectList(msg.getDetectorsList(),
    proto.Experiment.Location.DetectorSpectrum.toObject, includeInstance),
    contextImage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pseudoIntensitiesList: jspb.Message.toObjectList(msg.getPseudoIntensitiesList(),
    proto.Experiment.Location.PseudoIntensityData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.Location}
 */
proto.Experiment.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.Location;
  return proto.Experiment.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.Location}
 */
proto.Experiment.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScanSource(value);
      break;
    case 2:
      var value = new proto.Experiment.Location.MetaDataItem;
      reader.readMessage(value,proto.Experiment.Location.MetaDataItem.deserializeBinaryFromReader);
      msg.addMeta(value);
      break;
    case 3:
      var value = new proto.Experiment.Location.BeamLocation;
      reader.readMessage(value,proto.Experiment.Location.BeamLocation.deserializeBinaryFromReader);
      msg.setBeam(value);
      break;
    case 4:
      var value = /** @type {!proto.Experiment.Location.SpectrumCompression} */ (reader.readEnum());
      msg.setSpectrumCompression(value);
      break;
    case 5:
      var value = new proto.Experiment.Location.DetectorSpectrum;
      reader.readMessage(value,proto.Experiment.Location.DetectorSpectrum.deserializeBinaryFromReader);
      msg.addDetectors(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContextImage(value);
      break;
    case 7:
      var value = new proto.Experiment.Location.PseudoIntensityData;
      reader.readMessage(value,proto.Experiment.Location.PseudoIntensityData.deserializeBinaryFromReader);
      msg.addPseudoIntensities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanSource();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getMetaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Experiment.Location.MetaDataItem.serializeBinaryToWriter
    );
  }
  f = message.getBeam();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Experiment.Location.BeamLocation.serializeBinaryToWriter
    );
  }
  f = message.getSpectrumCompression();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDetectorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Experiment.Location.DetectorSpectrum.serializeBinaryToWriter
    );
  }
  f = message.getContextImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPseudoIntensitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.Experiment.Location.PseudoIntensityData.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.Experiment.Location.SpectrumCompression = {
  RAW: 0,
  ZERO_RUN: 1,
  RLE: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.Location.MetaDataItem.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.Location.MetaDataItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.Location.MetaDataItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.MetaDataItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelIdx: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    ivalue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    svalue: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.Location.MetaDataItem}
 */
proto.Experiment.Location.MetaDataItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.Location.MetaDataItem;
  return proto.Experiment.Location.MetaDataItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.Location.MetaDataItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.Location.MetaDataItem}
 */
proto.Experiment.Location.MetaDataItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLabelIdx(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFvalue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIvalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.Location.MetaDataItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.Location.MetaDataItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.Location.MetaDataItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.MetaDataItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelIdx();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getIvalue();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSvalue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 label_idx = 1;
 * @return {number}
 */
proto.Experiment.Location.MetaDataItem.prototype.getLabelIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.MetaDataItem} returns this
 */
proto.Experiment.Location.MetaDataItem.prototype.setLabelIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float fvalue = 2;
 * @return {number}
 */
proto.Experiment.Location.MetaDataItem.prototype.getFvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.MetaDataItem} returns this
 */
proto.Experiment.Location.MetaDataItem.prototype.setFvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 ivalue = 3;
 * @return {number}
 */
proto.Experiment.Location.MetaDataItem.prototype.getIvalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.MetaDataItem} returns this
 */
proto.Experiment.Location.MetaDataItem.prototype.setIvalue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string svalue = 4;
 * @return {string}
 */
proto.Experiment.Location.MetaDataItem.prototype.getSvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.Location.MetaDataItem} returns this
 */
proto.Experiment.Location.MetaDataItem.prototype.setSvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Experiment.Location.BeamLocation.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.Location.BeamLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.Location.BeamLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.Location.BeamLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.BeamLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    geomCorr: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    imageI: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    imageJ: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    contextLocationsList: jspb.Message.toObjectList(msg.getContextLocationsList(),
    proto.Experiment.Location.BeamLocation.Coordinate2D.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.Location.BeamLocation}
 */
proto.Experiment.Location.BeamLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.Location.BeamLocation;
  return proto.Experiment.Location.BeamLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.Location.BeamLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.Location.BeamLocation}
 */
proto.Experiment.Location.BeamLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZ(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGeomCorr(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImageI(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImageJ(value);
      break;
    case 6:
      var value = new proto.Experiment.Location.BeamLocation.Coordinate2D;
      reader.readMessage(value,proto.Experiment.Location.BeamLocation.Coordinate2D.deserializeBinaryFromReader);
      msg.addContextLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.Location.BeamLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.Location.BeamLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.Location.BeamLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.BeamLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getGeomCorr();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getImageI();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getImageJ();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getContextLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.Experiment.Location.BeamLocation.Coordinate2D.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.Location.BeamLocation.Coordinate2D.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.Location.BeamLocation.Coordinate2D} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.toObject = function(includeInstance, msg) {
  var f, obj = {
    i: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    j: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.Location.BeamLocation.Coordinate2D}
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.Location.BeamLocation.Coordinate2D;
  return proto.Experiment.Location.BeamLocation.Coordinate2D.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.Location.BeamLocation.Coordinate2D} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.Location.BeamLocation.Coordinate2D}
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setI(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setJ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.Location.BeamLocation.Coordinate2D.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.Location.BeamLocation.Coordinate2D} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getI();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getJ();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float i = 1;
 * @return {number}
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.prototype.getI = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.BeamLocation.Coordinate2D} returns this
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.prototype.setI = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float j = 2;
 * @return {number}
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.prototype.getJ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.BeamLocation.Coordinate2D} returns this
 */
proto.Experiment.Location.BeamLocation.Coordinate2D.prototype.setJ = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float x = 1;
 * @return {number}
 */
proto.Experiment.Location.BeamLocation.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.BeamLocation} returns this
 */
proto.Experiment.Location.BeamLocation.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float y = 2;
 * @return {number}
 */
proto.Experiment.Location.BeamLocation.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.BeamLocation} returns this
 */
proto.Experiment.Location.BeamLocation.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float z = 3;
 * @return {number}
 */
proto.Experiment.Location.BeamLocation.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.BeamLocation} returns this
 */
proto.Experiment.Location.BeamLocation.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float geom_corr = 7;
 * @return {number}
 */
proto.Experiment.Location.BeamLocation.prototype.getGeomCorr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.BeamLocation} returns this
 */
proto.Experiment.Location.BeamLocation.prototype.setGeomCorr = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float image_i = 4;
 * @return {number}
 */
proto.Experiment.Location.BeamLocation.prototype.getImageI = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.BeamLocation} returns this
 */
proto.Experiment.Location.BeamLocation.prototype.setImageI = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float image_j = 5;
 * @return {number}
 */
proto.Experiment.Location.BeamLocation.prototype.getImageJ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.BeamLocation} returns this
 */
proto.Experiment.Location.BeamLocation.prototype.setImageJ = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated Coordinate2D context_locations = 6;
 * @return {!Array<!proto.Experiment.Location.BeamLocation.Coordinate2D>}
 */
proto.Experiment.Location.BeamLocation.prototype.getContextLocationsList = function() {
  return /** @type{!Array<!proto.Experiment.Location.BeamLocation.Coordinate2D>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.Location.BeamLocation.Coordinate2D, 6));
};


/**
 * @param {!Array<!proto.Experiment.Location.BeamLocation.Coordinate2D>} value
 * @return {!proto.Experiment.Location.BeamLocation} returns this
*/
proto.Experiment.Location.BeamLocation.prototype.setContextLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.Experiment.Location.BeamLocation.Coordinate2D=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.Location.BeamLocation.Coordinate2D}
 */
proto.Experiment.Location.BeamLocation.prototype.addContextLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.Experiment.Location.BeamLocation.Coordinate2D, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment.Location.BeamLocation} returns this
 */
proto.Experiment.Location.BeamLocation.prototype.clearContextLocationsList = function() {
  return this.setContextLocationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Experiment.Location.DetectorSpectrum.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.Location.DetectorSpectrum.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.Location.DetectorSpectrum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.Location.DetectorSpectrum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.DetectorSpectrum.toObject = function(includeInstance, msg) {
  var f, obj = {
    metaList: jspb.Message.toObjectList(msg.getMetaList(),
    proto.Experiment.Location.MetaDataItem.toObject, includeInstance),
    spectrumList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    spectrummax: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.Location.DetectorSpectrum}
 */
proto.Experiment.Location.DetectorSpectrum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.Location.DetectorSpectrum;
  return proto.Experiment.Location.DetectorSpectrum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.Location.DetectorSpectrum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.Location.DetectorSpectrum}
 */
proto.Experiment.Location.DetectorSpectrum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Experiment.Location.MetaDataItem;
      reader.readMessage(value,proto.Experiment.Location.MetaDataItem.deserializeBinaryFromReader);
      msg.addMeta(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setSpectrumList(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpectrummax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.Location.DetectorSpectrum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.Location.DetectorSpectrum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.Location.DetectorSpectrum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.DetectorSpectrum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Experiment.Location.MetaDataItem.serializeBinaryToWriter
    );
  }
  f = message.getSpectrumList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getSpectrummax();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated MetaDataItem meta = 1;
 * @return {!Array<!proto.Experiment.Location.MetaDataItem>}
 */
proto.Experiment.Location.DetectorSpectrum.prototype.getMetaList = function() {
  return /** @type{!Array<!proto.Experiment.Location.MetaDataItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.Location.MetaDataItem, 1));
};


/**
 * @param {!Array<!proto.Experiment.Location.MetaDataItem>} value
 * @return {!proto.Experiment.Location.DetectorSpectrum} returns this
*/
proto.Experiment.Location.DetectorSpectrum.prototype.setMetaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Experiment.Location.MetaDataItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.Location.MetaDataItem}
 */
proto.Experiment.Location.DetectorSpectrum.prototype.addMeta = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Experiment.Location.MetaDataItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment.Location.DetectorSpectrum} returns this
 */
proto.Experiment.Location.DetectorSpectrum.prototype.clearMetaList = function() {
  return this.setMetaList([]);
};


/**
 * repeated int32 spectrum = 2;
 * @return {!Array<number>}
 */
proto.Experiment.Location.DetectorSpectrum.prototype.getSpectrumList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Experiment.Location.DetectorSpectrum} returns this
 */
proto.Experiment.Location.DetectorSpectrum.prototype.setSpectrumList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Experiment.Location.DetectorSpectrum} returns this
 */
proto.Experiment.Location.DetectorSpectrum.prototype.addSpectrum = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment.Location.DetectorSpectrum} returns this
 */
proto.Experiment.Location.DetectorSpectrum.prototype.clearSpectrumList = function() {
  return this.setSpectrumList([]);
};


/**
 * optional int32 spectrumMax = 3;
 * @return {number}
 */
proto.Experiment.Location.DetectorSpectrum.prototype.getSpectrummax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location.DetectorSpectrum} returns this
 */
proto.Experiment.Location.DetectorSpectrum.prototype.setSpectrummax = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Experiment.Location.PseudoIntensityData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.Location.PseudoIntensityData.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.Location.PseudoIntensityData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.Location.PseudoIntensityData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.PseudoIntensityData.toObject = function(includeInstance, msg) {
  var f, obj = {
    detectorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    elementIntensitiesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.Location.PseudoIntensityData}
 */
proto.Experiment.Location.PseudoIntensityData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.Location.PseudoIntensityData;
  return proto.Experiment.Location.PseudoIntensityData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.Location.PseudoIntensityData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.Location.PseudoIntensityData}
 */
proto.Experiment.Location.PseudoIntensityData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectorId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setElementIntensitiesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.Location.PseudoIntensityData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.Location.PseudoIntensityData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.Location.PseudoIntensityData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.Location.PseudoIntensityData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetectorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getElementIntensitiesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      2,
      f
    );
  }
};


/**
 * optional string detector_id = 1;
 * @return {string}
 */
proto.Experiment.Location.PseudoIntensityData.prototype.getDetectorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.Location.PseudoIntensityData} returns this
 */
proto.Experiment.Location.PseudoIntensityData.prototype.setDetectorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated float element_intensities = 2;
 * @return {!Array<number>}
 */
proto.Experiment.Location.PseudoIntensityData.prototype.getElementIntensitiesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Experiment.Location.PseudoIntensityData} returns this
 */
proto.Experiment.Location.PseudoIntensityData.prototype.setElementIntensitiesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Experiment.Location.PseudoIntensityData} returns this
 */
proto.Experiment.Location.PseudoIntensityData.prototype.addElementIntensities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment.Location.PseudoIntensityData} returns this
 */
proto.Experiment.Location.PseudoIntensityData.prototype.clearElementIntensitiesList = function() {
  return this.setElementIntensitiesList([]);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.Experiment.Location.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.Location} returns this
 */
proto.Experiment.Location.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 scan_source = 9;
 * @return {number}
 */
proto.Experiment.Location.prototype.getScanSource = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.Location} returns this
 */
proto.Experiment.Location.prototype.setScanSource = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated MetaDataItem meta = 2;
 * @return {!Array<!proto.Experiment.Location.MetaDataItem>}
 */
proto.Experiment.Location.prototype.getMetaList = function() {
  return /** @type{!Array<!proto.Experiment.Location.MetaDataItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.Location.MetaDataItem, 2));
};


/**
 * @param {!Array<!proto.Experiment.Location.MetaDataItem>} value
 * @return {!proto.Experiment.Location} returns this
*/
proto.Experiment.Location.prototype.setMetaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Experiment.Location.MetaDataItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.Location.MetaDataItem}
 */
proto.Experiment.Location.prototype.addMeta = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Experiment.Location.MetaDataItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment.Location} returns this
 */
proto.Experiment.Location.prototype.clearMetaList = function() {
  return this.setMetaList([]);
};


/**
 * optional BeamLocation beam = 3;
 * @return {?proto.Experiment.Location.BeamLocation}
 */
proto.Experiment.Location.prototype.getBeam = function() {
  return /** @type{?proto.Experiment.Location.BeamLocation} */ (
    jspb.Message.getWrapperField(this, proto.Experiment.Location.BeamLocation, 3));
};


/**
 * @param {?proto.Experiment.Location.BeamLocation|undefined} value
 * @return {!proto.Experiment.Location} returns this
*/
proto.Experiment.Location.prototype.setBeam = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Experiment.Location} returns this
 */
proto.Experiment.Location.prototype.clearBeam = function() {
  return this.setBeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Experiment.Location.prototype.hasBeam = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SpectrumCompression spectrum_compression = 4;
 * @return {!proto.Experiment.Location.SpectrumCompression}
 */
proto.Experiment.Location.prototype.getSpectrumCompression = function() {
  return /** @type {!proto.Experiment.Location.SpectrumCompression} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Experiment.Location.SpectrumCompression} value
 * @return {!proto.Experiment.Location} returns this
 */
proto.Experiment.Location.prototype.setSpectrumCompression = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated DetectorSpectrum detectors = 5;
 * @return {!Array<!proto.Experiment.Location.DetectorSpectrum>}
 */
proto.Experiment.Location.prototype.getDetectorsList = function() {
  return /** @type{!Array<!proto.Experiment.Location.DetectorSpectrum>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.Location.DetectorSpectrum, 5));
};


/**
 * @param {!Array<!proto.Experiment.Location.DetectorSpectrum>} value
 * @return {!proto.Experiment.Location} returns this
*/
proto.Experiment.Location.prototype.setDetectorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Experiment.Location.DetectorSpectrum=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.Location.DetectorSpectrum}
 */
proto.Experiment.Location.prototype.addDetectors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Experiment.Location.DetectorSpectrum, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment.Location} returns this
 */
proto.Experiment.Location.prototype.clearDetectorsList = function() {
  return this.setDetectorsList([]);
};


/**
 * optional string context_image = 6;
 * @return {string}
 */
proto.Experiment.Location.prototype.getContextImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.Location} returns this
 */
proto.Experiment.Location.prototype.setContextImage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated PseudoIntensityData pseudo_intensities = 7;
 * @return {!Array<!proto.Experiment.Location.PseudoIntensityData>}
 */
proto.Experiment.Location.prototype.getPseudoIntensitiesList = function() {
  return /** @type{!Array<!proto.Experiment.Location.PseudoIntensityData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.Location.PseudoIntensityData, 7));
};


/**
 * @param {!Array<!proto.Experiment.Location.PseudoIntensityData>} value
 * @return {!proto.Experiment.Location} returns this
*/
proto.Experiment.Location.prototype.setPseudoIntensitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.Experiment.Location.PseudoIntensityData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.Location.PseudoIntensityData}
 */
proto.Experiment.Location.prototype.addPseudoIntensities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.Experiment.Location.PseudoIntensityData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment.Location} returns this
 */
proto.Experiment.Location.prototype.clearPseudoIntensitiesList = function() {
  return this.setPseudoIntensitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Experiment.PseudoIntensityRange.prototype.toObject = function(opt_includeInstance) {
  return proto.Experiment.PseudoIntensityRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Experiment.PseudoIntensityRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.PseudoIntensityRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    channelEnd: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Experiment.PseudoIntensityRange}
 */
proto.Experiment.PseudoIntensityRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Experiment.PseudoIntensityRange;
  return proto.Experiment.PseudoIntensityRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Experiment.PseudoIntensityRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Experiment.PseudoIntensityRange}
 */
proto.Experiment.PseudoIntensityRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChannelStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChannelEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Experiment.PseudoIntensityRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Experiment.PseudoIntensityRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Experiment.PseudoIntensityRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Experiment.PseudoIntensityRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelStart();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getChannelEnd();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.Experiment.PseudoIntensityRange.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment.PseudoIntensityRange} returns this
 */
proto.Experiment.PseudoIntensityRange.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 channel_start = 2;
 * @return {number}
 */
proto.Experiment.PseudoIntensityRange.prototype.getChannelStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.PseudoIntensityRange} returns this
 */
proto.Experiment.PseudoIntensityRange.prototype.setChannelStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 channel_end = 3;
 * @return {number}
 */
proto.Experiment.PseudoIntensityRange.prototype.getChannelEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment.PseudoIntensityRange} returns this
 */
proto.Experiment.PseudoIntensityRange.prototype.setChannelEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string target_id = 1;
 * @return {string}
 */
proto.Experiment.prototype.getTargetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setTargetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 drive_id = 11;
 * @return {number}
 */
proto.Experiment.prototype.getDriveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setDriveId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 site_id = 12;
 * @return {number}
 */
proto.Experiment.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string target = 13;
 * @return {string}
 */
proto.Experiment.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string site = 14;
 * @return {string}
 */
proto.Experiment.prototype.getSite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setSite = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string title = 15;
 * @return {string}
 */
proto.Experiment.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string sol = 25;
 * @return {string}
 */
proto.Experiment.prototype.getSol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setSol = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int32 rtt = 17;
 * @return {number}
 */
proto.Experiment.prototype.getRtt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setRtt = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 sclk = 18;
 * @return {number}
 */
proto.Experiment.prototype.getSclk = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setSclk = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string bulk_sum_quant_file = 9;
 * @return {string}
 */
proto.Experiment.prototype.getBulkSumQuantFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setBulkSumQuantFile = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string detector_config = 10;
 * @return {string}
 */
proto.Experiment.prototype.getDetectorConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setDetectorConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 bulk_spectra = 19;
 * @return {number}
 */
proto.Experiment.prototype.getBulkSpectra = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setBulkSpectra = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 dwell_spectra = 20;
 * @return {number}
 */
proto.Experiment.prototype.getDwellSpectra = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setDwellSpectra = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 max_spectra = 21;
 * @return {number}
 */
proto.Experiment.prototype.getMaxSpectra = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setMaxSpectra = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 normal_spectra = 22;
 * @return {number}
 */
proto.Experiment.prototype.getNormalSpectra = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setNormalSpectra = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 pseudo_intensities = 23;
 * @return {number}
 */
proto.Experiment.prototype.getPseudoIntensities = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setPseudoIntensities = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional string main_context_image = 2;
 * @return {string}
 */
proto.Experiment.prototype.getMainContextImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setMainContextImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ScanSource scan_sources = 27;
 * @return {!Array<!proto.Experiment.ScanSource>}
 */
proto.Experiment.prototype.getScanSourcesList = function() {
  return /** @type{!Array<!proto.Experiment.ScanSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.ScanSource, 27));
};


/**
 * @param {!Array<!proto.Experiment.ScanSource>} value
 * @return {!proto.Experiment} returns this
*/
proto.Experiment.prototype.setScanSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.Experiment.ScanSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.ScanSource}
 */
proto.Experiment.prototype.addScanSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.Experiment.ScanSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.clearScanSourcesList = function() {
  return this.setScanSourcesList([]);
};


/**
 * repeated ContextImageCoordinateInfo aligned_context_images = 24;
 * @return {!Array<!proto.Experiment.ContextImageCoordinateInfo>}
 */
proto.Experiment.prototype.getAlignedContextImagesList = function() {
  return /** @type{!Array<!proto.Experiment.ContextImageCoordinateInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.ContextImageCoordinateInfo, 24));
};


/**
 * @param {!Array<!proto.Experiment.ContextImageCoordinateInfo>} value
 * @return {!proto.Experiment} returns this
*/
proto.Experiment.prototype.setAlignedContextImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.Experiment.ContextImageCoordinateInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.ContextImageCoordinateInfo}
 */
proto.Experiment.prototype.addAlignedContextImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.Experiment.ContextImageCoordinateInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.clearAlignedContextImagesList = function() {
  return this.setAlignedContextImagesList([]);
};


/**
 * repeated string unaligned_context_images = 6;
 * @return {!Array<string>}
 */
proto.Experiment.prototype.getUnalignedContextImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setUnalignedContextImagesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.addUnalignedContextImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.clearUnalignedContextImagesList = function() {
  return this.setUnalignedContextImagesList([]);
};


/**
 * repeated MatchedContextImageInfo matched_aligned_context_images = 26;
 * @return {!Array<!proto.Experiment.MatchedContextImageInfo>}
 */
proto.Experiment.prototype.getMatchedAlignedContextImagesList = function() {
  return /** @type{!Array<!proto.Experiment.MatchedContextImageInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.MatchedContextImageInfo, 26));
};


/**
 * @param {!Array<!proto.Experiment.MatchedContextImageInfo>} value
 * @return {!proto.Experiment} returns this
*/
proto.Experiment.prototype.setMatchedAlignedContextImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.Experiment.MatchedContextImageInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.MatchedContextImageInfo}
 */
proto.Experiment.prototype.addMatchedAlignedContextImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.Experiment.MatchedContextImageInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.clearMatchedAlignedContextImagesList = function() {
  return this.setMatchedAlignedContextImagesList([]);
};


/**
 * repeated string meta_labels = 3;
 * @return {!Array<string>}
 */
proto.Experiment.prototype.getMetaLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setMetaLabelsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.addMetaLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.clearMetaLabelsList = function() {
  return this.setMetaLabelsList([]);
};


/**
 * repeated MetaDataType meta_types = 4;
 * @return {!Array<!proto.Experiment.MetaDataType>}
 */
proto.Experiment.prototype.getMetaTypesList = function() {
  return /** @type {!Array<!proto.Experiment.MetaDataType>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.Experiment.MetaDataType>} value
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.setMetaTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.Experiment.MetaDataType} value
 * @param {number=} opt_index
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.addMetaTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.clearMetaTypesList = function() {
  return this.setMetaTypesList([]);
};


/**
 * repeated Location locations = 5;
 * @return {!Array<!proto.Experiment.Location>}
 */
proto.Experiment.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.Experiment.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.Location, 5));
};


/**
 * @param {!Array<!proto.Experiment.Location>} value
 * @return {!proto.Experiment} returns this
*/
proto.Experiment.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Experiment.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.Location}
 */
proto.Experiment.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Experiment.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * repeated PseudoIntensityRange pseudo_intensity_ranges = 8;
 * @return {!Array<!proto.Experiment.PseudoIntensityRange>}
 */
proto.Experiment.prototype.getPseudoIntensityRangesList = function() {
  return /** @type{!Array<!proto.Experiment.PseudoIntensityRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Experiment.PseudoIntensityRange, 8));
};


/**
 * @param {!Array<!proto.Experiment.PseudoIntensityRange>} value
 * @return {!proto.Experiment} returns this
*/
proto.Experiment.prototype.setPseudoIntensityRangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.Experiment.PseudoIntensityRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Experiment.PseudoIntensityRange}
 */
proto.Experiment.prototype.addPseudoIntensityRanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.Experiment.PseudoIntensityRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Experiment} returns this
 */
proto.Experiment.prototype.clearPseudoIntensityRangesList = function() {
  return this.setPseudoIntensityRangesList([]);
};


goog.object.extend(exports, proto);
